import React from 'react'
import '../App.css';

const Term = () => {
  return (
    <div className='terms'>

        <h3 className='text-center'>TERMS OF SERVICE</h3>
        <h3 className='text-center'>WEBSITE TERMS OF USE </h3>
        <br></br>

        <p>
          Welcome to www.inclusional.com. The www.inclusional.com website (the “Site/ Inclusional”) is comprised of various web pages operated by Jet Social Impact Private Limited. (“Jet Social Impact). www.inclusional.com is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the “Terms”). Your use of www.inclusional.com constitutes your agreement to all such Terms. Please read these terms carefully and keep a copy of them for your reference. 
        </p>

        <p>
          <b>The Site</b> is a News and Information website to provide information about the Company, its Products & Services and its related Entities and Partners. 
        </p>

  
        <p>
          <b>Privacy</b><br></br>
          Your use of the Site is subject to Jet Social Impact’s Privacy Policy. Please review our Privacy Policy at (insert link here), which also governs the Site and informs users of our data collection practices. 
        </p>

  

        <p>
          <b>Electronic Communications</b><br></br>
          Visiting the Site or sending emails to Inclusional constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing. 
        </p>
        <p>
          <b>Your account</b><br></br>
          If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that Jet Social Impact is not responsible for third party access to your account that results from theft or misappropriation of your account. Jet Social Impact and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion. 
        </p>

  
        <p>
          Inclusional  does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use the Site only with permission of a parent or guardian. 
        </p>

  
        <p>
          <b>Links to third party sites/Third party services</b><br></br>
          The Site may contain links to other websites (“Linked Sites”). The Linked Sites are not under the control of Jet Social Impact, and it is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Jet Social Impact is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement byJ et Social Impact of the site or any association with its operators. 
        </p> 
        <p>
          Certain services made available via the Site are delivered by third party sites and organizations. By using any product, service or functionality originating from the Inclusional domain, you hereby acknowledge and consent that Jet Social Impact may share such information and data with any third party with whom Jet Social Impact has a contractual relationship to provide the requested product, service or functionality on behalf of its users and customers. 
        </p>
  
        <p>
          <b>No unlawful or prohibited use/Intellectual Property </b><br></br>
          You are granted a non-exclusive, non-transferable, revocable license to access and use www.inclusional.com strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to Jet Social Impact that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site. 
        </p>

        <p>
          All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of Jet Social Impact or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto. 
        </p>
  
        <p>
          You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. Inclusional content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use and will make no other use of the content without the express written permission of Jet Social Impact and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of Jet Social Impact or our licensors except as expressly authorized by these Terms. 
        </p>
  

  
        <p>
          <b>International Users</b><br></br>
          The Service is controlled, operated and administered by Jet Social Impact from our offices in India. If you access the Service from a location outside India, you are responsible for compliance with all local laws. You agree that you will not use the Jet Social Impact Content accessed through the Site in any country or in any manner prohibited by any applicable laws, restrictions or regulations. 
        </p>
  
        <p><b>Indemnification</b></p>

        <p>You agree to indemnify, defend and hold harmless Jet Social Impact , its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorneys’ fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. Jet Social Impact reserves the right, at its own cost, to assume the exclusive defence and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with Jet Social Impact in asserting any available defences. </p>

  
        <p><b>Liability disclaimer</b></p>

        <p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. JETSYNTHESYS AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.</p>

        <p>
          JET SOCIAL IMPACT AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED “AS IS” WITHOUT WARRANTY OR CONDITION OF ANY KIND. JET SOCIAL IMPACT AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. 
        </p>
  
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL JET SOCIAL IMPACT AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF JET SOCIAL IMPACT OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
        </p>
  

 
        <p><b>Termination/access restriction</b></p>

        <p>Jet Social Impact reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of Maharashtra, India and you hereby consent to the exclusive jurisdiction and venue of courts in Mumbai in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.</p>
    
        <p>You agree that no joint venture, partnership, employment, or agency relationship exists between you and Jet Social Impact as a result of this agreement or use of the Site. Jet Social Impact’s performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of  Jet Social Impact’s right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by  Jet Social Impact with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.</p>
    

        <p>Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and Jet Social Impact with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and Jet Social Impact with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.</p>

        <p><b>Changes to Terms</b></p>
        <p>
          Inclusional reserves the right, in its sole discretion, to change the Terms under which www.inclusional.com is offered. The most current version of the Terms will supersede all previous versions. Jet Social Impact encourages you to periodically review the Terms to stay informed of our updates. 
        </p>

        <p><b>Contact Us</b></p>
        <p>Inclusional, Jet Social Impact welcomes your questions or comments regarding the Terms: </p>
        <p>Jet Social Impact Private Limited </p>
        <p><b>Registered Address:</b></p>
        <p>FL. NO.23, ORCHID APT., CTS 121+122, RAMBAUG COLONY, PAUD ROAD, KOTHRUD, PUNE - 411038, Maharashtra, India</p>
        <p><b> Corporate Address: </b></p>
        <p>6th & 7th Floor, Sky One, Kalyani Nagar, Pune - 411006 </p>
        <p>Email Address: connect@inclusional.com  </p>

      
    </div>
  )
}

export default Term
