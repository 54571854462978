
const LOCAL_URL = 'http://localhost:3005/api/v2/org';
const LOGIN_BASE_URL =  `${process.env.REACT_APP_SSO_API_URL}/v1/auth`;
const BASE_URL = `${process.env.REACT_APP_ADMIN_API_URL}/api/v2/org`;


const handleResponse = async (response) => {
  if (!response.ok) {
    const errorData = await response.json();
    const errorMessage = errorData.message || 'Something went wrong';
    
    // Throw specific error types based on HTTP status code
    if (response.status === 401) {
      throw new UnauthorizedError(errorMessage);
    } else if (response.status === 403) {
      throw new ForbiddenError(errorMessage);
    } else {
      throw new Error(errorMessage);
    }
  }
  return response.json();
};

// Custom error classes
class UnauthorizedError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UnauthorizedError';
  }
}

class ForbiddenError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ForbiddenError';
  }
}

const apiService = {
  get: async (endpoint, token) => {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      headers: {
        token: token,
        'Content-Type': 'application/json',
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      },
    });

    return handleResponse(response);
  },

  post: async (endpoint, data, token) => {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'POST',
      headers: {
        token: token,
        'Content-Type': 'application/json',
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      },
      body: JSON.stringify(data),
    });
    return handleResponse(response);
  },

  local: async (endpoint, data, token) => {
    const response = await fetch(`${LOCAL_URL}/${endpoint}`, {
      method: 'POST',
      headers: {
        token: token,
        'Content-Type': 'application/json',
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      },
      body: JSON.stringify(data),
    });

    return handleResponse(response);
  },

  login: async (endpoint, data, token) => {
    const response = await fetch(`${LOGIN_BASE_URL}/${endpoint}`, {
      method: 'POST',
      headers: {
        token: token,
        'Content-Type': 'application/json',
         "app-key": 'DIB-00cd6ed7-10a0-4045-9a33-0081c0a32166',
      },
      body: JSON.stringify(data),
    });

    return handleResponse(response);
  },

  // Add other HTTP methods (put, delete, etc.) as needed

  // Function to set a token to be used in subsequent requests
  setAuthToken: (token) => {
    localStorage.setItem('authToken', token);
  },

  // Function to get the token
  getAuthToken: () => {
    return localStorage.getItem('authToken');
  },

  // Function to remove the token (logout)
  removeAuthToken: () => {
    localStorage.removeItem('authToken');
  },
};

export default apiService;