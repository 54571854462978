import React from 'react';
import '../App.css';


const Privacy = () => {
    return (
        <div className='terms'>
            <h3><b>Privacy Policy</b></h3>

            <p>Jet Social Impact Pvt. Ltd.., (the “Company”) is committed to maintaining robust privacy protections for its users. Our Privacy Policy (“Privacy Policy”) is designed to help you understand how we collect, use and safeguard the information you provide to us and to assist you in making informed decisions when using our Service. </p>

             <p>
                For purposes of this Agreement, “Service” refers to the Company's service which can be accessed via our website at www.inclusional.com in which users can learn about our products and services and can link through to the websites specifically for each of these products. The terms “we,” “us,” and “our” refer to the Company. “You” refers to you, as a user of Service. By accepting our Privacy Policy and Terms of Use (found here:'www.inclusional/privacy_policy), you consent to our collection, storage, use and disclosure of your personal information as described in this Privacy Policy.   
            </p>

            <p>
                For purposes of this Agreement, “Service” refers to the Company's service which can be accessed via our website at www.inclusional.com in which users can learn about our products and services and can link through to the websites specifically for each of these products. The terms “we,” “us,” and “our” refer to the Company. “You” refers to you, as a user of Service. By accepting our Privacy Policy and Terms of Use (found here:'www.inclusional/privacy_policy), you consent to our collection, storage, use and disclosure of your personal information as described in this Privacy Policy. 
            </p>

            <p><b>INFORMATION WE COLLECT</b></p>

            <p>
                We collect “Non-Personal Information” and “Personal Information.” Non-Personal Information includes information that cannot be used to personally identify you, such as anonymous usage data, general demographic information we may collect, referring/exit pages and URLs, platform types, preferences you submit and preferences that are generated based on the data you submit and number of clicks. Personal Information includes only your email, which you submit to us through the registration process at the Site. 
            </p>

            <p><b>1. Information collected via Technology</b></p>

            <p>
                To activate the Service you do not need to submit any Personal Information other than your email address. To use the Service thereafter, you do not need to submit further Personal Information. However, in an effort to improve the quality of the Service, we track information provided to us by your browser or by our software application when you view or use the Service, such as the website you came from (known as the “referring URL”), the type of browser you use, the device from which you connected to the Service, the time and date of access, and other information that does not personally identify you. We track this information using cookies, or small text files which include an anonymous unique identifier. Cookies are sent to a user’s browser from our servers and are stored on the user’s computer hard drive. Sending a cookie to a user’s browser enables us to collect Non-Personal information about that user and keep a record of the user’s preferences when utilizing our services, both on an individual and aggregate basis. 
            </p>
            <p>
                For example, the Company may use cookies to collect the following information: 

            </p>
        
            <ul>
                <li>
                    If you've visited the site before. 
                </li>
                <li>
                    If you've filled forms on the site before. 
                </li>
            </ul>
            
            <p>The Company may use both persistent and session cookies; persistent cookies remain on your computer after you close your session and until you delete them, while session cookies expire when you close your browser. 
            </p>

            <p><b>2. Information you provide us by registering for an account </b></p>

            <p>In addition to the information provided automatically by your browser when you visit the Site, to become a subscriber to the Service you will need to create a personal profile. You can create a profile by registering with the Service and entering your email address, and creating a username and a password. By registering, you are authorizing us to collect, store and use your email address in accordance with this Privacy Policy.</p>

            <h6><b>HOW WE USE AND SHARE INFORMATION </b></h6><br></br>

            <p><b>Personal Information: </b></p>

            <p>Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or otherwise share for marketing purposes your Personal Information with third parties without your consent. We do share Personal Information with vendors who are performing services for the Company, such as the servers for our email communications who are provided access to user's email address for purposes of sending emails from us. 
            Those vendors use your Personal Information only at our direction and in accordance with our Privacy Policy. In general, the Personal Information you provide to us is used to help us communicate with you. For example, we use Personal Information to contact users in response to questions, solicit feedback from users, provide technical support, and inform users about promotional offers. 
            </p>

            <p><b>Non-Personal Information: </b></p>
            <p>
                In general, we use Non-Personal Information to help us improve the Service and customize the user experience. We also aggregate Non-Personal Information to track trends and analyze use patterns on the Site. This Privacy Policy does not limit in any way our use or disclosure of Non-Personal Information and we reserve the right to use and disclose such Non-Personal Information to our partners, advertisers and other third parties at our discretion. 
                In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our assets, your Personal Information may be among the assets transferred. You acknowledge and consent that such transfers may occur and are permitted by this Privacy Policy, and that any acquirer of our assets may continue to process your Personal Information as set forth in this Privacy Policy. If our information practices change at any time in the future, we will post the policy changes to the Site so that you may opt out of the new information practices. We suggest that you check the Site periodically if you are concerned about how your information is used. 
            </p>


            <h6><b>HOW WE PROTECT INFORMATION</b></h6><br></br>

            <p>We implement security measures designed to protect your information from unauthorized access. Your account is protected by your account password, and we urge you to take steps to keep your personal information safe by not disclosing your password and by logging out of your account after each use. We further protect your information from potential security breaches by implementing certain technological security measures including encryption, firewalls and secure socket layer technology. However, these measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By using our Service, you acknowledge that you understand and agree to assume these risks.</p>

            <h6><b>YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION</b></h6>

            <p>
            You have the right at any time to prevent us from contacting you for marketing purposes. When we send a promotional communication to a user, the user can opt out of further promotional communications by following the unsubscribe instructions provided in each promotional e-mail. You can also indicate that you do not wish to receive marketing communications from us in the “Settings” section of the Site. Please note that notwithstanding the promotional preferences you indicate by either unsubscribing or opting out in the Settings section of the Site, we may continue to send you administrative emails including, for example, periodic updates to our Privacy Policy. 
            </p>

            <h6><b>LINKS TO OTHER WEBSITES </b></h6>

            <p>As part of the Service, we may provide links to or compatibility with other websites or applications. However, we are not responsible for the privacy practices employed by those websites or the information or content they contain. This Privacy Policy applies solely to information collected by us through the Site and the Service. Therefore, this Privacy Policy does not apply to your use of a third-party website accessed by selecting a link on our Site or via our Service. To the extent that you access or use the Service through or on another website or application, then the privacy policy of that other website or application will apply to your access or use of that site or application. We encourage our users to read the privacy statements of other websites before proceeding to use them.</p>

            <h6><b>CHANGES TO OUR PRIVACY POLICY</b></h6>

            <p>The Company reserves the right to change this policy and our Terms of Service at any time. We will notify you of significant changes to our Privacy Policy by sending a notice to the primary email address specified in your account or by placing a prominent notice on our site. Significant changes will go into effect 30 days following such notification. Non-material changes or clarifications will take effect immediately. You should periodically check the Site and this privacy page for updates. </p>

            <h6><b>CONTACT US</b></h6>
            <p>If you have any questions regarding this Privacy Policy or the practices of this Site, please contact us by sending an email to connect@inclusional.com</p>
            <p>Last Updated: This Privacy Policy was last updated on 04th April, 2022. </p>

            <p><b>Introduction and Purpose:</b></p>

            <p>This Privacy Policy applies whenever you use our Services, which means our App and services and other content operated by  Jet Social Impact. It describes how we collect, store, use, disclose, and otherwise process the information you provide us when using our Services, and your rights and choices concerning your information. </p>

            <p>This also describes the choices available to you regarding our use of your personal information and how you can access and update this information. You can find additional tools and information in the ‘Settings’ tab of the profile you created by signing-up for an account with the App. </p>

            <p><b>Information We Collect:</b></p>

            <p>To use the Services, you provide us with various kinds of information. We collect and store any information that you provide to us, including when you create your account or a player profile. We use this information for the purposes described in this Privacy Policy. The type of information that we collect depends on how you use the Services. You can learn how to access and delete information that we collect by visiting the ‘Settings’ tab of your profile on the App.</p>

            <p><b>2.1 Information About You That You Share with Us Directly</b></p>

            <p>User profile information like Name, Gender, Mobile number, Date of Birth, Email and profile picture provided by you. </p>

            <p>Information and content you share on our platforms, such as product reviews. We also record and collect information of the actions you perform while using the Services.</p>

            <p>Instead of creating an account with us directly, you may connect and sign up to the Services with your account with another service provider such as Twitter, Google, or Facebook. In such cases we will receive information from those services, such as your email address, friends, or contacts list. If you access our Services from a third-party application or connect our Services to a third-party application, you should also read that third-party application’s terms of service and privacy policy.</p>

            <p>If you email or contact us, the content of your message, your email address, and your other contact information would be stored by us to respond to your request.</p>

            <p>We also receive and collect information about the manner in which you use the Services. We use such information to operate or personalize our services and ensure their secure, reliable, and robust performance. Such information allows us to improve our content and make it more meaningful and relevant for you. It also allows us to make suggestions about content you would like and features that you would be interested in. </p>

            <p><b>2.2 Information You Generate Using Our Services</b></p>

            <p>We collect information about the ways in which you use and interact with the Services, such as when and for how long you play the game, game features and other players you interact with, purchases you make, progress or levels you achieve, and other gameplay activities. We also receive information about the frequency of these interactions and use.</p>

            <p>We receive information about the links (internal and third party) which you interact with across the Services, and we also receive information about how you interact with these links.</p>

            <p>In addition to the above, when you use the Services, we also receive information about your IP address, browser, operating system, the referring web page, pages visited, approximate location, your mobile carrier, device information (including device and application IDs), search terms, and cookie information.</p>

            <p><b>2.3 Device Information</b></p>
            <p>
            We receive and collect information about and from each of the devices you use, to access and use the Services. We use this information to personalise and improve our content, better design our services for you and make it more meaningful and relevant for you. Information that we obtain from these devices includes: 
            </p>

            <p>
            Device attributes: information such as the operating system, hardware and software versions, battery level, signal strength, available storage space, browser type, app and file names and types, and plugins. 
            </p>

            <p>Device operations: information about operations and behaviors performed on the device, such as whether a window is foregrounded or backgrounded, or mouse movements (which can help distinguish humans from bots). </p>

<p>Identifiers: unique identifiers, device IDs and other identifiers, such as from games, apps or accounts that you use. 
</p>

<p>Device signals: Bluetooth signals, information about nearby Wi-Fi access points, beacons and mobile phone masts. 
</p>

<p>Data from device settings: information that you allow us to receive through device settings that you turn on, such as access to your GPS location, camera or photos, other apps installed on your device. 
</p>

<p>Network and connections: information such as the name of your mobile operator or ISP, language, time zone, mobile phone number, IP address, connection speed and, in some cases, information about other devices that are nearby or on your network.</p>

<p>Cookie data: data from cookies stored on your device, including cookie IDs and settings. </p>

<p>We work with various partners, such as advertisers, vendors, service providers, developers, third parties whose APIs are integrated with the Service. We receive information from these partners provide about your activities outside of the App – such as information about your device, websites you visit, purchases you make, the adverts you see and how you use their services. They receive your data when you visit or use their services or through third parties that they work with. We require each of these partners to have lawful rights to collect, use and share your data before providing any data to us. </p>
 
<p><b>2.4 Payment Information</b></p>

<p>
We do not record or store any kind of payment information including your credit or debit card number, card expiration date, CVV code, and billing address, in order to make purchase other offerings provided as part of the Services. </p>

<p><b>Cookies and Other Tracking Technologies</b></p>
 <p>
As applies to most Apps, we use cookies or other technologies in combination with your information to enhance and personalize your experience, for example, to keep track of your preferences and profile information. Cookies are also used to collect general usage and volume statistical information that does not include personal information. 
</p>

<p>
We automatically gather information about your computer and device such as your IP address, browser type, referring/exit pages, operating system, pages visited, location, your mobile carrier, device information (including device and application IDs), search terms, and cookie information. 
</p>

<p>
You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. However, it’s important to remember that many of the features on our App may not function properly if your cookies are disabled. 
</p>

<p><b>How We Use Information We Collect</b></p>

<p>The primary purpose for which we use the information that we receive is for providing you to provide, personalize, maintain the security of, and improve the Services you use on our App. </p>

<p>We use such information to operate or personalize our services and ensure their secure, reliable, and robust performance. Such information enables us to make inferences, understand the use of our App and therefore allows us to improve our content, better design our services for you and make it more meaningful and relevant for you. It also allows us to make suggestions about content including ads you would like and features that you would be interested in. </p>

<p>We also make use of the information for the purposes of developing, testing and improving our Services, including by way of conducting surveys and research, and testing and troubleshooting new products and features. </p>

<p>In addition, we may use your personal information to: </p>

<p>Personalize your experience as per your preferences </p>

<p>Save some manual typing efforts by auto populating some information </p>

<p>Verify your unique user account within our system. </p>

<p>Send you requested product or service information; </p>

<p>Administer your account; </p>

<p>Send you a newsletter; </p>

<p>Send you marketing communications; </p>

<p>Respond to your questions and concerns; </p>

<p>Improve our App and marketing efforts; </p>

<p>Conduct research and analysis; </p>

<p>Offer other products, features, or services that may be of interest to you. </p>

<p>We use the information and the inferences we draw from processing information to help advertisers and other partners measure the effectiveness and distribution of their ads and services, and understand the types of people who use their services and how people interact with their websites, apps and services. We conduct data analytics to provide analytics and measurement reports. </p>

<p>In communications sent to you, we use the information to send you communications related to our App, marketing content, sponsored content, related to our terms and conditions and to reply to you when you contact us. </p>

<p><b>Advertising </b></p>

<p>Advertising is our key and primary source of revenue and allows us to provide you the App and most of its features for free. We may use ad networks to customize and display advertising on our App. However, we do not share any information about you, as a user, with any ad network and service providers. We may from time-to-time work with other companies for certain services such as analytics or advertising, and you may be subject to their privacy policies as well. </p>

<p><b>Safety, Integrity and Security.</b> </p>

<p>We use the information for verification of accounts and your activity to prevent any misuse of our App, or any harmful conduct either towards you or towards the general community and therefore provide safety and maintain integrity and Security of our Services. </p>

<p><b>Information Sharing</b></p>

<p>For the purposes mentioned here, we do disclose certain personal information we collect or receive, however we do so subject to your control, or because it’s necessary to operate our services, or because it’s required by law. With your consent or at your direction or on your instructions, we share or disclose personal information, like when you authorize a third-party web client or application to access your account. </p>

<p>Subject to the settings controls you have provided us with, we also disclose your personal information with certain third parties for allowing us offer or operate our App. For instance, we disclose to the advertiser’s certain specific information when you see or engage with their ads, which enables them to measure the productivity and effectiveness of their adverts and our advertising proposition. </p>

<p>We also share device identifiers, along with the interests or other characteristics of a device or the person using it, to help partners decide whether to serve an ad to that device or to enable them to conduct marketing, brand analysis, interest-based advertising, or similar activities. </p>

<p>The information we share with these partners does not include your name, email address, phone number, or username, but some of these partnerships allow the information we share to be linked to other personal information if the partner gets your consent first. </p>

<p><b>Services Providers </b></p>

<p>We engage vendors and service providers to perform functions and provide services to us, to help operate our services, such as by providing technical infrastructure services, analysing how our App is used, providing analytics and measurement reports (which reports we may share with our partners) providing customer service, facilitating payments or conducting surveys. </p>

<p>We may share your private personal data with such service providers subject to obligations consistent with this Privacy Policy and any other appropriate confidentiality and security measures, and on the condition that the third parties use your private personal data only on our behalf and pursuant to our instructions. </p>

<p><b>Law, Harm, and the Public Interest </b></p>

<p>Notwithstanding anything to the contrary in this Privacy Policy or controls we may otherwise offer to you, we may reserve, use, or disclose your personal data if: </p>

<p>We believe that it is reasonably necessary to comply with a law, regulation, legal process, or governmental request, including for national security, law enforcement, or legal process purposes (for example, a court order, search warrant, etc). </p>

<p>To protect the health and/or safety of you, another person, or the public generally. </p>

<p>To protect the safety or integrity of our platform, including to help prevent spam, abuse, or malicious actors on our services, or to explain why we have removed content or accounts from our services. </p>

<p>To address fraud, security, or technical issues. </p>

<p>To protect our rights or property or the rights or property of those who use our services. </p>


<p>Business Transfer and Change of Ownership:  </p>

<p>In the event that we are involved in a bankruptcy, merger, acquisition, reorganization, or sale of assets, we may disclose or transfer all of your information, including personal information, to the successor organization in the transition. This Privacy Policy will apply to your personal data as transferred to the new entity. We may also disclose personal data about you to our corporate affiliates in order to help operate our services and our affiliates’ services, including the delivery of ads. </p>

<p>We may also share aggregated, non-personally identifiable information, publicly and with our partners like publishers, advertisers or connected sites. For example, we may share information publicly to show trends about the general use of our services, the number of people who clicked on a particular link,or reports to advertisers about how many people saw or clicked on their ads. </p>

<p>Links to Other Web Sites </p>

<p>Our App includes links to other apps or websites whose privacy practices may differ from those of JetSynthesys. If you submit personal information to any of those sites, your information is governed by their privacy statements. We encourage you to carefully read the privacy statement of any Web site you visit. </p>


<p>Security </p>

<p>The security of your personal information is important to us. When you enter sensitive information, we encrypt the transmission of that information using secure socket layer technology (SSL). </p>

<p>We follow accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure, therefore, we cannot guarantee absolute security. </p>

<p>If you have any questions about security on the Service, you can contact us at connect@inclusional.com </p>


<p>How Long We Keep Your Information </p>

<p>Personal information provided by you that we use to provide you services on the App is retained by us until it is no longer necessary to provide our services or until your account is deleted – whichever comes first. We keep information collected on the App for a maximum of 180 days. </p>

<p>The length of time we retain personally identifiable information for depends on the purposes for which we collect and use it and/or as required to comply with applicable laws and to establish, exercise or defend our legal rights. </p>


<p>Managing your Personal Information </p>

<p>To review and update your personal information to ensure it is accurate, contact us at  connect@inclusional.com If you believe your information is wrong, we strive to give you ways to update it quickly or to delete it unless we have to keep that information for legitimate business or legal purposes. </p>

<p>When updating your personal data, we may ask you to verify your identity before we can act on your request. We may reject requests that are unreasonably repetitive, require disproportionate technical effort (for example, developing a new system or fundamentally changing an existing practice), risk the privacy of others, or would be extremely impractical (for instance, requests concerning information residing on backup tapes). </p>


<p>For EU Residents </p>

<p>You are in control of your personal information which we have received or collected. </p>

<p>Accessing or Rectifying Your Personal Data: </p>

 

<p>We provide you with tools and account settings to access, correct, delete, or modify the personal data you provided to us and associated with your account. You can request correction, deletion, or modification of your personal data, and download your account information, by reaching out to us at hello@jetsynthesys.com. </p>

<p>You can object to processing of your personal information, ask us to restrict processing of your personal information. If we have collected and processed your personal information with your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent. </p>

<p>You can opt out of marketing communications we send you. </p>

<p>You have the right to complain to a data protection authority about our collection and use of your personal information. For more information, please contact your local data protection authority. </p>

<p>You can learn more about the interests we have inferred about you and request access to additional information by reaching out to us at connect@inclusional.com </p>

<p>Deletion: </p>

<p>To deactivate or delete your account please write to us at  connect@inclusional.com. Upon deactivation, your account, including your display name, username, and public profile, will no longer be viewable on the App. Thereafter for up to 30 days, you will be entitled to restore your account and for that period we will retain your personal information. Information that others have shared about you isn’t part of your account and won’t be deleted. </p>


<p>If you would like further information in relation to your rights or would like to exercise any of them, you may also contact us via  connect@inclusional.com </p>

<p>If you reside or are located in the EEA, you have the right to request that we: </p>

<p>Provide access to any personal information we hold about you; </p>

<p>Prevent the processing of your Personal Information for direct-marketing purposes; </p>

<p>Update any Personal Information which is out of date or incorrect; </p>

<p>Delete any Personal Information which we are holding about you; </p>

<p>Restrict the way that we process your Personal Information;</p> 

<p>Provide your Personal Information to a third-party provider of services; </p>

<p>Provide you with a copy of any Personal Information which we hold about you.</p>
 
    <p>
    We try to answer every email promptly where possible,and provide our response within the time period stated by applicable law. Keep in mind, however, that there will be residual information that will remain within our databases, access logs and other records, which may or may not contain your Personally Information. Please also note that certain Personal Information may be exempt from such requests in certain circumstances, which may include if we need to keep processing your Personally Identifiable Information to comply with a legal obligation.</p>
 
    <p>When you email us with a request, we may ask that you provide us with information necessary to confirm your identity.</p>

    <p><b>International Operations and Transfers out of European Countries:</b></p>

    <p>For providing you the services on the Service, we operate globally and are required to transfer your information to countries where we operate for processing of such information, and by agreeing to use the Service, you authorise us to make such transfers. The laws governing privacy and data protection in such countries may differ from the laws in your country however we attempt to ensure an adequate level of protection for your information in compliance with receiving country’s data protection laws, and by placing contractual obligations on the recipient of the data (in case of third parties). </p>

    <p><b>Our Policies Concerning Children </b></p>

    <p>We do not direct our App to children and to use our Services you must be at least 13 years of age. If you are a resident of EU then you must be at least 16 years of age. You must also be old enough to provide a legal and binding consent (as determined by the laws of your country) to the processing of your personal data (in some countries we may allow your parent or guardian to do so on your behalf).</p>

    <p><b>Changes to this Privacy Policy</b></p>
 
    <p>We may update this Privacy Policy from time to time. The most current version of the Privacy Policy will govern our processing of your personal data and will always be available on the App/at ___________.</p>
 
    <p>If we make any amends, which we determine in our sole discretion to be material, we will notify you via a App update or email to the email address associated with your account. By continuing to access or use the Services after those changes become effective, you agree to be bound by the revised Privacy Policy.</p>
 
    <p><b>Disclaimer</b></p>

    <p>Except as may be clearly and expressly stated by Jet Social Impact otherwise, Jet Social Impact makes no representation of any endorsement, association or connection with any player, club, association or organisation existing now or in the future and any similarity with any graphical element of this game is purely co-incidental and made with bonafide intentions. Jet Social Impact neither intends nor practices any infringement of intellectual property of any kind in any way shape or form, and if you or any person you represent believe that this game or any aspect thereof infringes on your intellectual property, please write to us at  connect@inclusional.com  along with the reason and aspect of the game you believe to be infringing. Jet Social Impact use all reasonable and required efforts to ensure compliance with all applicable laws and regulations.</p>
 

 
</div>

 
    )

}

export default Privacy
