import React, {useState} from 'react';
import './login.css';
// import OtpInput from 'react-otp-input';
import OtpInput from 'react18-input-otp';

const OtpVerification = () => {
    const [otp, setOtp] = useState('');
    return (
        <div className='container'>
            <div className='row'>
            <div className='col-md-6'><img src='/images/login-bg.png' alt="login" className='w-100 d-none p-4' /></div>
                <div className='col-md-6'>
                    <div className='login-otp text-center py-2 d-flex flex-column justify-content-center align-items-center'>
                        <div className='login-details text-center mb-4'>
                            <h3 className='login-title'>Hi, Nidhi Shah</h3>
                            <p className='mb-0'>Welcome to inclusional, please enter the OTP sent to your</p>
                            <p className='mb-0'>email id <b>nidhi.shah@gmail.com</b> below to become</p>
                            <p className='mb-0'><b>Admin</b> for your organisation</p>
                        </div>

                        <div className='login-otpinput'>
                            <p className='text-left font-20'><b>Enter OTP</b></p>
                            {/* <OtpInput
                                value={otp}
                                numInputs={6}
                                renderSeparator={<span style={{ width: "22px" }}></span>}
                                isInputNum={true}
                                shouldAutoFocus={true}
                                inputStyle={{
                                border: "1px solid #CCC",
                                borderRadius: "7px",
                                width: "69px",
                                height: "66px",
                                fontSize: "20px",
                                color: "#000",
                                fontWeight: "400",
                                caretColor: "black",
                                margin: "0px auto"
                                }}
                                renderInput={(props) => <input {...props} />}
                            /> */}
                            <div className='otp_input'>
                                <OtpInput 
                                    value={otp} 
                                    numInputs={6} 
                                    inputStyle={{
                                        border: "1px solid #CCC",
                                        borderRadius: "7px",
                                        width: "65px",
                                        height: "66px",
                                        fontSize: "20px",
                                        color: "#000",
                                        fontWeight: "400",
                                        caretColor: "black",
                                        margin: "10px"
                                    }} 
                                />

                            </div>
        
                            <p className='resend-otp'>
                                <span className='login-resend'>You can resend OTP in</span> 
                                <span className='login-time'>00.15</span>
                            </p>
                        </div>

                        <div className='text-center w-100'> <a href="/dashboard" className='btn btn-md btn-primary w-75 otp-btn mt-4'>Confirm</a></div>
                        <p className='login-note'>By proceeding you agree to our <a href='/term' target='_blank' className='login-term text-decoration-none'>Terms and Conditions</a> and <a href='/privacy' target='_blank' className='login-term text-decoration-none'>Privacy Policy</a></p>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default OtpVerification;
