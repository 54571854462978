import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Term from "./pages/Term";
import ReactGA from "react-ga4";
import Login from "./pages/login/login";

ReactGA.initialize("G-7300WC0WHX");

function App() {
  return (
    <Router>
       <ToastContainer />
      <Routes>
        <Route exact path="/" element={<Home />} />{" "}
        <Route path="/term" element={<Term />} />{" "}
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/login" element={<Login />} />
      </Routes>{" "}
    </Router>
  );
}

export default App;
